var render = function render(){var _vm=this,_c=_vm._self._c;return _c('yt-live-chat-renderer',{staticClass:"style-scope yt-live-chat-app",staticStyle:{"--scrollbar-width":"11px"},attrs:{"hide-timestamps":"","danmaku-at-bottom":_vm.danmakuAtBottom,"ticker-at-buttom":_vm.tickerAtButtom},on:{"mousemove":_vm.refreshCantScrollStartTime}},[_c('ticker',{staticClass:"style-scope yt-live-chat-renderer",attrs:{"messages":_vm.paidMessages,"showGiftInfo":_vm.showGiftInfo}}),_c('yt-live-chat-item-list-renderer',{staticClass:"style-scope yt-live-chat-renderer",attrs:{"allow-scroll":""}},[_c('div',{ref:"scroller",staticClass:"style-scope yt-live-chat-item-list-renderer animated",attrs:{"id":"item-scroller"},on:{"scroll":_vm.onScroll}},[_c('div',{ref:"itemOffset",staticClass:"style-scope yt-live-chat-item-list-renderer",staticStyle:{"height":"0px"},style:(`${(this.randomXOffset || this.randomYOffset) ? 'overflow: visible;' : 'overflow: hidden; position: relative;'}`),attrs:{"id":"item-offset"}},[_c('div',{ref:"items",staticClass:"style-scope yt-live-chat-item-list-renderer",style:({ transform: `translateY(${Math.floor(_vm.scrollPixelsRemaining)}px)`,
            overflow: `${(this.randomXOffset || this.randomYOffset) ? 'visible !important' : 'hidden !important'}`
           }),attrs:{"id":"items"}},[_c('transition-group',{staticClass:"style-scope yt-live-chat-item-list-renderer",attrs:{"tag":"div","css":false,"id":"chat-items"},on:{"leave":_vm.onMessageLeave}},[_vm._l((_vm.messages),function(message){return [(message.type === _vm.MESSAGE_TYPE_INTERACT)?_c('interact-message',{key:message.id,staticClass:"style-scope yt-live-chat-item-list-renderer",style:(`--x-offset:${message.xOffset}px;
                  --y-offset:${message.yOffset}px;
                  --float-distance-x: ${message.floatDistanceX}px;
                  --float-distance-y: ${message.floatDistanceY}px;
                  --float-time: ${_vm.getFloatTime}s;`),attrs:{"randomXOffset":_vm.randomXOffset,"randomYOffset":_vm.randomYOffset,"time":message.time,"avatarUrl":message.avatarUrl,"authorName":message.authorName,"medalName":message.medalName,"medalLevel":message.medalLevel,"isFanGroup":message.isFanGroup,"privilegeType":message.privilegeType,"msgType":message.msgType,"isDelete":message.isDelete}}):(message.type === _vm.MESSAGE_TYPE_TEXT)?_c('text-message',{key:message.id,staticClass:"style-scope yt-live-chat-item-list-renderer",style:(`--x-offset:${message.xOffset}px;
                  --y-offset:${message.yOffset}px;
                  --float-distance-x: ${message.floatDistanceX}px;
                  --float-distance-y: ${message.floatDistanceY}px;
                  --float-time: ${_vm.getFloatTime}s;`),attrs:{"mergeSameUserDanmaku":_vm.mergeSameUserDanmaku,"textColor":message.textColor,"randomXOffset":_vm.randomXOffset,"randomYOffset":_vm.randomYOffset,"time":message.time,"avatarUrl":message.avatarUrl,"authorName":message.authorName,"authorType":message.authorType,"privilegeType":message.privilegeType,"repeated":message.repeated,"repeatedThread":message.repeatedThread,"threadLength":message.threadLength,"medalName":message.medalName,"medalLevel":message.medalLevel,"isFanGroup":message.isFanGroup,"isDelete":message.isDelete,"richContents":_vm.getShowRichContentThread(message)}}):(message.type === _vm.MESSAGE_TYPE_GIFT)?_c('paid-message',{key:message.id,staticClass:"style-scope yt-live-chat-item-list-renderer",style:(`--x-offset:${message.xOffset}px;
                  --y-offset:${message.yOffset}px;
                  --float-distance-x: ${message.floatDistanceX}px;
                  --float-distance-y: ${message.floatDistanceY}px;
                  --float-time: ${_vm.getFloatTime}s;`),attrs:{"randomXOffset":_vm.randomXOffset,"randomYOffset":_vm.randomYOffset,"time":message.time,"avatarUrl":message.avatarUrl,"authorName":_vm.getShowAuthorName(message),"price":message.price,"content":_vm.getGiftShowContent(message),"giftName":message.giftName,"isDelete":message.isDelete}}):(message.type === _vm.MESSAGE_TYPE_MEMBER)?_c('membership-item',{key:message.id,staticClass:"style-scope yt-live-chat-item-list-renderer",style:(`--x-offset:${message.xOffset}px;
                  --y-offset:${message.yOffset}px;
                  --float-distance-x: ${message.floatDistanceX}px;
                  --float-distance-y: ${message.floatDistanceY}px;
                  --float-time: ${_vm.getFloatTime}s;`),attrs:{"randomXOffset":_vm.randomXOffset,"randomYOffset":_vm.randomYOffset,"time":message.time,"avatarUrl":message.avatarUrl,"authorName":_vm.getShowAuthorName(message),"price":message.price,"guardNum":message.guardNum,"guardUnit":message.guardUnit,"privilegeType":message.privilegeType,"title":message.title,"isDelete":message.isDelete}}):(message.type === _vm.MESSAGE_TYPE_SUPER_CHAT)?_c('paid-message',{key:message.id,staticClass:"style-scope yt-live-chat-item-list-renderer",style:(`--x-offset:${message.xOffset}px;
                  --y-offset:${message.yOffset}px;
                  --float-distance-x: ${message.floatDistanceX}px;
                  --float-distance-y: ${message.floatDistanceY}px;
                  --float-time: ${_vm.getFloatTime}s;`),attrs:{"giftName":"superchat","randomXOffset":_vm.randomXOffset,"randomYOffset":_vm.randomYOffset,"time":message.time,"avatarUrl":message.avatarUrl,"authorName":_vm.getShowAuthorName(message),"price":message.price,"content":_vm.getShowContent(message),"isDelete":message.isDelete}}):_vm._e()]})],2)],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }